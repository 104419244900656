
import store from '@/store'

export default {
    // 学校档次  
    SchoolLevel(schoolLevelsString) {  
        if (!schoolLevelsString) {  
            return []; // 如果没值，返回空数组  
        }  
        const rawLevels = schoolLevelsString.split(",").filter(level => level.trim() !== '');  
        // 排序  985 > 211 > 双一流 > 双非
        rawLevels.sort((a, b) => {  
            if (a === "985") return -1;  
            if (b === "985") return 1;  
            return a.localeCompare(b);  
        });  
        return rawLevels;  
    },

     //档次样式
     LevelStyle(level) {  
        switch (level) {
            case "985":
              return {
                backgroundColor: "#FFF3E8",
                color: "#FF9225",
                border: "1px solid #FF8F1F",
                padding: "2px 8px",
                borderRadius: "4px",
                marginRight: "5px",
                fontSize: "12px",
              };
            case "211":
              return {
                backgroundColor: "#E9F8E5",
                color: "#4ACC2C",
                border: "1px solid #24C200",
                padding: "2px 8px",
                borderRadius: "4px",
                marginRight: "5px",
                fontSize: "12px",
              };
            case "双一流":
              return {
                backgroundColor: "#F3EBFD",
                color: "#8F41F5",
                border: "1px solid #8A38F5",
                padding: "2px 8px",
                borderRadius: "4px",
                marginRight: "5px",
                fontSize: "12px",
              };
            case "双非":
              return {
                backgroundColor: "#E5F7FE",
                color: "#0BBCFC",
                border: "1px solid #00B9FC",
                padding: "2px 8px",
                borderRadius: "4px",
                marginRight: "5px",
                fontSize: "12px",
              };
          }
      },
      //title样式
      titleStyle(level) {  
        const Tags = ["压分", "不保护一志愿", "复试比大", "死亡硕士点", "同等学力不友好", "双非慎报", "参考书多", "专业课难度大", "招生人数少", "高手多", "火爆硕士点"];  
        if (Tags.includes(level)) {  
            return {  
                backgroundColor: "#FEF0F0",  
                color: "#F56C6C",  
                padding: "2px 10px",  
                borderRadius: "4px",  
                marginRight: "5px",  
                fontSize: "12px",  
            };  
        } else {  
            return {  
                backgroundColor: "#F0F9EB",  
                color: "#67C23A",  
                padding: "2px 10px",  
                borderRadius: "4px",  
                marginRight: "5px",  
                fontSize: "12px",  
            };  
        }  
    },
    address(item) {
        if (!item.place) {
          return;
        }
        if(item.city == null){
          let address = "";
          return address = item.place
        }
        let address = "";
        address = item.place + '-' + item.city;
        let addlist = ['北京市', '天津市', '重庆市', '上海市']
        addlist.forEach(res => {
          if (res == item.place) {
            address = item.place;
          }
        });
        return address;
  },

  //保存路由
  baocunrout(url,name,type){
    let itxs = store.state.holdroute;
    itxs.push({ url: url, name: name, type: type});
    const uniqueArr = [...new Set(itxs.map(JSON.stringify))].map(JSON.parse);
    //保存路由
    store.commit("baocuntabrout", uniqueArr);
  },


}