import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//axios
import axios from 'axios';
Vue.prototype.$axios = axios;

//element UI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 防抖
import debounceClick from './utils/debounce';   
Vue.directive('debounce-click', debounceClick);  
  
Vue.config.productionTip = false;

import Utils from '@/utils/index';
Vue.prototype.utils=Utils;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
