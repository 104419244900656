import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  //vuex的基本数据，用来存储变量
  state: {
    // 登录状态
    user: false,
    iskeep:'1',
    // 保存的 路由地址
    holdroute:[{
      url:"/",
      name:"首页",
      type:""
    }],

  },
  
  //相当于state的计算属性
  getters: {
  },
  //vuex 的方法，必须是同步的(如果需要异步使用action)
  mutations: {
    // 登录
    login(state, user) {
      state.user = user;
      //登录状态
      localStorage.setItem("usertype", user);
    },
    // 退出
    logout(state) {
      state.user = "";
      //登录状态
      localStorage.setItem("usertype", false);
      //个人信息
      localStorage.setItem("userdata", "");
      // 清空对应的 key token
      localStorage.removeItem("TJlogintoken")
      //清空所有
      localStorage.clear();
    },
    //保存-路由
    baocuntabrout(state,holdroute){
      state.holdroute=holdroute;
    },
    //学校
    keepSchool(state,holdroute){
      state.iskeep=holdroute;
    },
     //还原默认 路由
    reduction(state){
      state.holdroute=[{
        url:"/",
        name:"首页",
         type:""
    }]
  }
    
  },
  actions: {
  },
  modules: {
  }
})
