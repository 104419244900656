//防抖
export default {  
  // 绑定
  bind(el, binding) {  
    let debounceTimeout;  
    el._clickHandler = function (event) {  
      // 清除之前的定时器  
      clearTimeout(debounceTimeout);  
      // 设置新的定时器  
      debounceTimeout = setTimeout(() => {  
        binding.value(event);  
      }, 300);
    };  
    el.addEventListener('click', el._clickHandler);  
  },  
  // 解绑
  unbind(el) {  
    // 移除事件监听器  
    el.removeEventListener('click', el._clickHandler);  
  }  
};